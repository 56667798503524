import Config from "./models/config";
import { ITask } from "./models/task";
import { IUserInfo } from "./models/userInfo";

export const checkPermissionChangeTask = ({
    user,
    task,
    newTask,
    onFeedBack,
}: {
    user: IUserInfo;
    task: ITask;
    newTask: ITask;
    onFeedBack: (msg: string) => void;
}): boolean => {
    if (!user) {
        onFeedBack("Bạn phải đăng nhập mới có thể thay đổi!");
        return false;
    }
    const currentUserId = user._id;
    // task thay đổi cột
    const isChangeTaskStatus = task.statusId != newTask.statusId;
    const isChangedTaskInfo =
        task.failedReason != newTask.failedReason ||
        task.testLink != newTask.testLink ||
        task.description != newTask.description ||
        task.deadline != newTask.deadline ||
        task.point != newTask.point ||
        task.confirmedPoints != newTask.confirmedPoints ||
        task.consumedPoints != newTask.consumedPoints;
    
    const isChangeDesOrDeadline = task.description != newTask.description ||task.deadline != newTask.deadline;

    const isChangeExpectPoints = task.leadDevPoints != newTask.leadDevPoints;
    const changeCheckPoint = task.checkPoint != newTask.checkPoint;
    const isCreatedUser = task.createdUserId == currentUserId;
    // Admin được phép thay đổi sang mọi giá trị.
    const isAdmin = user.role == Config.USER_ROLE_ADMIN;
    // PO được phép thay đổi sang mọi giá trị.
    const po = task.project && task.project.ownerID == currentUserId;

    // Lead dev được phép thay đổi status sang in progress, in code review, to deploy, prod validation.
    const leadDev = task.leadDevId == currentUserId || isLeadDev(user);

    // Tester được phép thay đổi status sang in progress, in code review.
    const tester = task.testerIds.includes(currentUserId);

    // Các thành viên ko phải assignee hoặc reporter thì ko được quyền thay đổi task.
    const assignee = currentUserId == task.assigneeId; // assignee
    const reporter = currentUserId == task.reporterId; //  reporter

    // leader chỉ được quản lý set point
    const leaderPoint = isLeaderEditPoint(user); 

    if (isAdmin || po) {
        return true;
    }
    // console.log("leaderPoint", leadDev, leaderPoint, isChangeExpectPoints);
    if (assignee || reporter || tester || leadDev || isCreatedUser || leaderPoint && isChangeExpectPoints) {
        if (isChangeTaskStatus) {
            if (reporter) return true;
            const roleForTester = [Config.COLUMN_STATUS.REVIEW_CODE.mongoId, Config.COLUMN_STATUS.IN_PROGRESS.mongoId];
            const roleForLeadDev = [
                ...roleForTester,
                Config.COLUMN_STATUS.TO_DEPLOY.mongoId,
                Config.COLUMN_STATUS.PROD_VALIDATION.mongoId,
            ];
            const roleForAssignee = [
                Config.COLUMN_STATUS.TO_DO.mongoId,
                Config.COLUMN_STATUS.IN_PROGRESS.mongoId,
                Config.COLUMN_STATUS.QA_TESTING.mongoId,
            ];
            const checkLeadDev = leadDev && roleForLeadDev.includes(newTask.statusId); // nếu là leadDev
            const checkTester = tester && roleForTester.includes(newTask.statusId);
            const checkAssignee = assignee && roleForAssignee.includes(newTask.statusId);
            if (checkLeadDev || checkTester || checkAssignee) {
                const checkHasPoint = typeof newTask.point === "number" && (newTask.point == 0 || newTask.point > 0);
                if (checkTester || ((checkLeadDev || checkAssignee) && checkHasPoint)) {
                    return true;
                } else {
                    onFeedBack(`Bạn phải tạo branch trên github hoặc thêm số points!`);
                    return false;
                }
            } else {
                onFeedBack(`Bạn không có quyền thay đổi sang trạng thái sang "${newTask.status?.title}"!`);
                return false;
            }
        } else if ((assignee && isChangedTaskInfo) 
                || (leaderPoint && isChangeExpectPoints) 
                || (leadDev && isChangeDesOrDeadline)
                || (leadDev && isChangedTaskInfo) // moi them vao, lead dev co the sua dc task
                || reporter || isCreatedUser) {
            // nếu là assignee và chỉ sửa những thông tin: số points, testLink, deadline
            // nếu là người tạo hoặc người reporter của task đó thì mới có quyền sửa thông tin task
            return true;
        }
        else if (changeCheckPoint && tester) {
            // tester được checkPoint
            return true
        }
    }
    onFeedBack("Bạn không có quyền thay đổi!");
    return false;
};

export const getLeadDev = (users: IUserInfo[]) => {
    return users ? users.filter((user) => user.status == Config.STATUS_PUBLIC && isLeadDev(user)) : [];
};

export const getStaff = (users: IUserInfo[]) => {
    return users ? users.filter((user) => user.status == Config.STATUS_PUBLIC && !isPartner(user)) : [];
};

export const isDev = (user: IUserInfo) => {
    return !!(user?.position && user.position.toLowerCase().indexOf("developer") > -1);
};

export const isDesign = (user: IUserInfo) => {
    return !!(user?.position && user.position.toLowerCase().indexOf("design") > -1);
};

export const isMarketer = (user: IUserInfo) => {
    return !!(user?.position && user.position.toLowerCase().indexOf("marketer") > -1);
};

export const isContent = (user: IUserInfo) => {
    return !!(user?.position && user.position.toLowerCase().indexOf("content") > -1);
};

export const isLeadDev = (user: IUserInfo) => {
    return !!(user?.position && user.position.toLowerCase().indexOf("lead dev") > -1);
};

export const isIntern = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_INTERN : false;
};
export const isPartner = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_PARTNER : false;
};

export const isStaff = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_STAFF : false;
};

export const isAdmin = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_ADMIN : false;
};

export const isCreateBlog = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_CREATE_BLOG : false;
};

export const isLeader = (user: IUserInfo) => {
    return user?.role ? user.role === Config.USER_ROLE_LEADER : false;
};

export const isLeaderEditPoint = (user: IUserInfo) => {
    return user?.role ? (user.role === Config.USER_ROLE_LEADER || user.role === Config.USER_ROLE_MID_LEADER) : false;
};

export const getTester = (users: IUserInfo[]) => {
    return users
        ? users.filter((user) => {
              return user.status == Config.STATUS_PUBLIC && user.position && user.position.toLowerCase().indexOf("tester") > -1;
          })
        : [];
};

export const getAdmin = (users: IUserInfo[]) => {
    return users ? users.filter((user) => user.role === Config.USER_ROLE_ADMIN) : [];
};

